import styled, { keyframes } from 'styled-components'
import theme from 'src/theme'

const gradient = keyframes`
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 50%;
  }
`
export const ComboboxLoadingSkeletal = styled.div`
  border-radius: 4px;
  display: flex;
  padding-left: 15px;
  background: linear-gradient(
    -45deg,
    ${theme.colors.common.white3},
    ${theme.colors.common.white4},
    ${theme.colors.common.white}
  );
  background-size: 400% 400%;
  animation: ${gradient} 4s ease infinite;
  height: 51px;
  width: 100%;
`
