import { useRef } from 'react'
import styled from 'styled-components'
import theme from 'src/theme'
import Image from 'next/image'
import { Typing } from 'src/ui-kit/loaders/typing/Typing'
import LocationIcon from '../../../public/gps_fixed_black_24dp.svg'

export type Props = {
  isLoading: boolean
  getToggleButtonProps?: any
  showMenuOnClickOfInput?: boolean
}

export const InputWrapper = styled.div`
  display: flex;
  border: 3px solid transparent;
  overflow: hidden;
  background-color: ${theme.colors.common.white3};
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  font-family: ${theme.fontFamily.primary};
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  :focus-within {
    border: 3px solid ${theme.colors.primary};
    background-color: ${theme.colors.common.white};
  }
`

export const StrippedInput = styled.input`
  background-color: inherit;
  border: none;
  outline: none;
  width: 100%;
  padding: 16px 0;
  font-family: inherit;
  font-size: 15px;
`

export const AddressInput = ({ isLoading, getToggleButtonProps, showMenuOnClickOfInput, ...rest }: Props) => {
  const hiddenToggleButton = useRef<HTMLButtonElement>()

  const handleInputElementClick = () => {
    /**
     * The Task: open the menu when user taps on the input field.
     *
     * The Problem: Downshift doesn't expose (afaik) any api which does that.
     *
     * The Workaround: The closest that you could get is using `getToggleButtonProps()`
     * which, as you may have guessed, is for a toggle button.
     * Now that is a neat initiative by our lord KCD. And since downshift heavily focuses on accessibility compliant code,
     * if we try to put getToggleButtonProps in an input, it will transform it into a button.
     * Since... it's supposed to be for... a.. *drumroll* button. -_-
     *
     * But we don't want no buttons. Hence, we have a button ref which is clicked on click of the input.
     * And this button is always hidden. We can go ahead and attach `getToggleButtonProps` on _this_ little guy
     * So it will feel like the menu is opening up
     * when the user clicks on the input element with this neat little hack.
     *
     * Look ma! I am a hacker!:P
     */

    if (showMenuOnClickOfInput) {
      hiddenToggleButton.current?.click()
    }
  }

  return (
    <InputWrapper>
      <div style={{ margin: '0 14px', display: 'flex' }}>
        <Image width={25} height={25} src={LocationIcon} alt='current location' />
      </div>
      <StrippedInput type='text' onClick={() => handleInputElementClick()} {...rest} />
      <div style={{ marginRight: '16px' }}>
        <Typing isVisible={isLoading} />
      </div>
      {showMenuOnClickOfInput && (
        <button ref={hiddenToggleButton} {...getToggleButtonProps()} style={{ display: 'none' }} />
      )}
    </InputWrapper>
  )
}

AddressInput.defaultProps = {
  getToggleButtonProps: () => ({}),
  showMenuOnClickOfInput: false,
}
