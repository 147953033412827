import { createContext, PropsWithChildren } from 'react'
import { createReducer } from 'react-use'
import { reportReducer, INITIAL_STATE } from './reducers'
import { reportAnalyticsMiddleware } from 'src/middlewares/report/analytics'
import { Context, State, Action } from './types'

export const ReportContext = createContext<Context>({
  reportState: INITIAL_STATE,
  dispatch: () => null,
})

ReportContext.displayName = 'ReportAVehicleContext'

const useReportTracking = createReducer<Action, State>(reportAnalyticsMiddleware)

/* TODO: next iteration: move into just 1 provider and move on to redux */
export const ReportProvider = ({ children }: PropsWithChildren<{}>) => {
  const [reportState, dispatch] = useReportTracking(reportReducer, INITIAL_STATE)
  return <ReportContext.Provider value={{ reportState, dispatch }}>{children}</ReportContext.Provider>
}
