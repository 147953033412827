import { useContext } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'next-i18next'
import { FormView, View } from 'src/ui-kit/View'
import { Heading, Paragraph } from 'src/ui-kit/typography'
import { Select } from 'src/ui-kit/nativeSelect/NativeSelect'
import { TextArea } from 'src/ui-kit/nativeTextArea/NativeTextArea'
import { SelectWrapper } from 'src/ui-kit/selectWrapper/SelectWrapper'
import { REASON_CATEGORIES, REPORT_REASONS } from 'src/constants/reportReasons'
import { Button } from 'src/ui-kit/button'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'

const reasonSchema = Yup.object().shape({
  reportCategory: Yup.string().oneOf(Object.keys(REPORT_REASONS)).notOneOf([REASON_CATEGORIES.select]).required(),
  reportReason: Yup.string()
    .oneOf([
      ...REPORT_REASONS[REASON_CATEGORIES.badParking],
      ...REPORT_REASONS[REASON_CATEGORIES.antiSocialBehavior],
      ...REPORT_REASONS[REASON_CATEGORIES.other],
    ])
    .notOneOf([REASON_CATEGORIES.select])
    .required(),
})

export type Props = {
  reportCategory: string
  reportReason: string
  reportComment?: string
}

export const ReasonView = () => {
  const { t } = useTranslation('common')

  const { reportState, dispatch } = useContext(ReportContext)
  const { currentPage, isSendingReport } = reportState

  const setReason = ({ reportCategory, reportReason, reportComment = '' }: Props) => {
    dispatch({
      type: ActionType.SET_REPORT,
      reportCategory,
      reportReason,
      reportComment,
    })
  }

  return (
    <FormView data-testid='submit-reason-view' isVisible={currentPage === Page.REASON}>
      <Formik
        initialValues={{
          reportReason: REASON_CATEGORIES.select,
          reportCategory: REASON_CATEGORIES.select, // only because its the same key in phrase translations. This will always be disabled.
          reportComment: '',
          /**
           * because Typescript cannot know the type while mapping the reasonCategory.
           * And since the mapped enum cannot be parsed as a string to be put inside the translations,
           * We put the initial value here.
           *
           */
        }}
        validateOnMount
        validationSchema={reasonSchema}
        onSubmit={values => setReason(values)}
      >
        {({ errors, values, values: { reportCategory, reportReason, reportComment } }) => {
          const reasonsInCategory: Array<string> = REPORT_REASONS[reportCategory]
          const isReasonSelectVisible: boolean = Boolean(REPORT_REASONS[reportCategory].length)
          const isCommentVisible: boolean =
            reasonsInCategory.includes(reportReason) && reportReason !== REASON_CATEGORIES.select
          return (
            <Form>
              <View isVisible>
                <div>
                  <Heading>{t('report_vehicle_reason_title')}</Heading>
                  <Paragraph leftAlign>{t('report_vehicle_reason_description')}</Paragraph>
                  <SelectWrapper isVisible>
                    <Select
                      as='select'
                      name='reportCategory'
                      placeholder={t('report_vehicle_reason_select')}
                      data-testid='reason-category-select'
                    >
                      {Object.keys(REPORT_REASONS).map((reportCategory, id) => (
                        <option value={reportCategory} key={id} disabled={reportCategory === REASON_CATEGORIES.select}>
                          {t(reportCategory)}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                  <SelectWrapper isVisible={isReasonSelectVisible} isSubCategory>
                    <Select
                      as='select'
                      name='reportReason'
                      data-testid='reason-select'
                      placeholder={t('report_vehicle_reason_select')}
                    >
                      {isReasonSelectVisible &&
                        reasonsInCategory.map((reason, id: number) => (
                          <option value={reason} key={id} disabled={reason === REASON_CATEGORIES.select}>
                            {t(reason)}
                          </option>
                        ))}
                    </Select>
                  </SelectWrapper>
                  <SelectWrapper isDisplayBlock={true} isVisible={isCommentVisible}>
                    <Paragraph marginTop={50} leftAlign>
                      {t('report_vehicle_reason_comment_description')}
                    </Paragraph>
                    <TextArea
                      as='textarea'
                      name='reportComment'
                      value={reportComment}
                      data-testid='report-comment-input'
                      placeholder={t('optional')}
                      rows={7}
                    />
                  </SelectWrapper>
                </div>
                <div>
                  <Button
                    data-testid='submit-report-button'
                    actionType='submit'
                    variant='primary'
                    isStretched
                    isDisabled={isSendingReport || Boolean(errors.reportCategory) || Boolean(errors.reportReason)}
                    isLoading={isSendingReport}
                  >
                    {t(`report_vehicle_bad_parking_reason_cta`)}
                  </Button>
                </div>
              </View>
            </Form>
          )
        }}
      </Formik>
    </FormView>
  )
}

export default ReasonView
