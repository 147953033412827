import axios from 'axios'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
const MAPBOX_PLACES_ENDPOINT = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
const DEBOUNCE_TIME = 500
export const useAsyncOptions = ({ searchText = '', params = {} }) => {
  const [responseData, setResponseData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()

  const makeNetworkRequest = useCallback(
    debounce(async searchText => {
      try {
        const response = await axios({ url: `${MAPBOX_PLACES_ENDPOINT}${encodeURI(searchText)}.json`, params })
        setError(undefined)
        setResponseData(response.data)
        setIsLoading(false)
      } catch (e) {
        setError(e)
        setIsLoading(false)
      }
    }, DEBOUNCE_TIME),
    [],
  )

  const fetchData = (searchText: string) => {
    // TODO: axios cancel token
    makeNetworkRequest(searchText)
  }

  useEffect(() => {
    if (searchText) {
      setIsLoading(true)
      setError(undefined)
      fetchData(searchText)
    }
  }, [searchText])

  return {
    data: responseData,
    isLoading,
    error,
  }
}
