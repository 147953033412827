import { FormEvent, MutableRefObject, useContext, useRef, useState } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Heading, Paragraph } from 'src/ui-kit/typography'
import { ImageThumbnail } from 'src/ui-kit/imageThumbnail'
import { Button } from 'src/ui-kit/button'
import { View } from 'src/ui-kit/View'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'
import theme from 'src/theme'

const PhotoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const PhotoButton = styled.button`
  margin-top: 16px;
  background-color: white;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  border: 3px solid transparent;
  padding: 0;
  &:focus-visible {
    outline: none;
    border: 3px solid ${theme.colors.primary};
  }
`

const ClickableImage = styled(Image)`
  cursor: pointer;
`

export const PhotoView = () => {
  const { t } = useTranslation('common')
  const [photos, setPhotos] = useState<File[]>([])
  const { reportState, dispatch } = useContext(ReportContext)
  const { currentPage } = reportState

  const addPhoto = (e: FormEvent<HTMLInputElement>) => {
    let fileSet = [...photos]
    if (e.currentTarget.files) {
      Array.prototype.forEach.call(e.currentTarget.files, file => {
        if (!fileSet.find(f => f.lastModified === file.lastModified && f.name === file.name)) {
          fileSet.push(file)
        }
      })
      setPhotos(fileSet)
    }
  }
  const removePhoto = ({ lastModified, name }: File) => {
    let fileSet = [...photos].filter(file => file.lastModified !== lastModified && file.name !== name)
    setPhotos(fileSet)
  }

  const submitPhotos = (photos: File[]) => {
    dispatch({
      type: ActionType.SET_PHOTOS,
      photos,
    })
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.REASON,
    })
  }

  const skipPhotos = () => {
    dispatch({
      type: ActionType.SKIP_PHOTOS,
    })

    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.REASON,
    })
  }

  const inputPhotoRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>
  return (
    <View data-testid='submit-photo-view' isVisible={currentPage === Page.PHOTOS}>
      <div>
        <Heading>{t('report_vehicle_photo_title')}</Heading>
        <Paragraph>{t('report_vehicle_photo_description')}</Paragraph>
        <PhotoWrapper>
          {photos.map((photo, idx) => (
            <div key={photo.lastModified} style={{ marginRight: '16px', marginTop: '16px' }}>
              <ImageThumbnail
                photoName={photo.name}
                src={URL.createObjectURL(photo)}
                width={92}
                height={80}
                onRemove={() => removePhoto(photos[idx])}
              />
            </div>
          ))}
          <PhotoButton onClick={() => inputPhotoRef.current.click()}>
            <ClickableImage src='/img-add_photo.svg' width={92} height={80} />
            <input
              data-testid='attach-photos-input'
              ref={inputPhotoRef}
              type='file'
              accept='image/jpg, image/jpeg, image/png'
              multiple
              style={{
                display: 'none',
              }}
              onChange={addPhoto}
            />
          </PhotoButton>
        </PhotoWrapper>
      </div>
      <div>
        <Button
          data-testid='submit-photos-button'
          actionType='button'
          isStretched
          isDisabled={photos.length === 0}
          onClick={() => submitPhotos(photos)}
        >
          {t('report_vehicle_photo_cta')}
        </Button>
        <div>
          <Button
            data-testid='skip-attaching-photos-button'
            actionType='button'
            variant='text'
            isStretched
            onClick={skipPhotos}
          >
            {t('report_vehicle_photo_skip_cta')}
          </Button>
        </div>
      </div>
    </View>
  )
}

export default PhotoView
