import styled from 'styled-components'
import theme from 'src/theme'

export const SelectWrapper = styled.div<{ isVisible: boolean , isSubCategory?: boolean; isDisplayBlock?: boolean}>`
  display: ${({ isDisplayBlock }) => isDisplayBlock ? 'block' : 'flex'};
  overflow: hidden;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  pointer-events: ${({ isVisible }) => isVisible ? 'all' : 'none'};
  transform: scale(${({ isVisible }) => isVisible ? 1 : 0.9});
  transition: transform 0.1s ease-in-out, opacity 0.2s ease-in-out, border-color 0.1s ease-in-out;
  margin-top: ${({ isSubCategory }) => isSubCategory ? '24px' : 0}};

  // a little line to show its a subcategory
  :before {
    content: '';
    display: block;
    width: ${({ isSubCategory }) => isSubCategory ? '3px' : 0};
    margin-right: ${({ isSubCategory }) => isSubCategory ? '24px' : 0};
    background-color: ${theme.colors.common.white2};
    transform: scale(${({ isSubCategory }) => isSubCategory ? 1 : 0});
    transition: background-color 0.1s ease-in-out;
  }
  :focus-within {
    :before {
      background-color: ${theme.colors.primary};
    }
  }
`