import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Heading, Paragraph } from 'src/ui-kit/typography'
import { Button } from 'src/ui-kit/button'
import { View } from 'src/ui-kit/View'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'
import { QRCodeBackButton } from './qrCodeBackButton/QRCodeBackButton'
import { AsyncCombobox } from 'src/ui-kit/asyncCombobox/AsyncCombobox'
import { ComboboxLoadingSkeletal } from 'src/ui-kit/asyncCombobox/LoadingSkeletal'
import LocationIcon from '../../../../public/gps_fixed_black_24dp.svg'
import { DateTimeInput } from '../../../ui-kit/input/DateTimeInput'

export const itemToString = (item: { place_name?: string }) => {
  if (item) {
    return item.place_name || ''
  }
  return ''
}

export const txRequestData = (networkRequestData: any) => {
  if (networkRequestData.features) {
    return networkRequestData.features.map((feature: any) => feature)
  }
}

const LocationView = () => {
  const { t } = useTranslation('common')
  const { reportState, dispatch } = useContext(ReportContext)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const { currentPage, reportedAt } = reportState
  const initialSelectedItemForAddress = reportState.currentLocationAddress?.length
    ? {
        place_name: reportState.currentLocationAddress,
      }
    : null

  const handleLocationSelect = (location: any) => {
    const { center, place_name } = location
    const [longitude, latitude] = center

    dispatch({
      type: ActionType.GET_USER_LOCATION_SUCCESS,
      coordinates: {
        latitude,
        longitude,
      },
      currentLocationAddress: place_name,
    })
  }

  const handleDateTimeSelect = (date: any) => {
    dispatch({
      type: ActionType.SET_INCIDENT_DATETIME,
      reportedAt: Date.parse(date),
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.PHOTOS,
    })
  }

  useEffect(() => {
    if (reportState.coordinates?.longitude && reportState.coordinates?.latitude) {
      return setIsSubmitDisabled(false)
    }
    return setIsSubmitDisabled(true)
  }, [reportState.coordinates])

  useEffect(() => {
    const currentTime = Date.now()
    dispatch({
      type: ActionType.SET_INCIDENT_DATETIME,
      reportedAt: currentTime,
    })
  }, [dispatch])

  return (
    <View data-testid='location-view' isVisible={currentPage === Page.LOCATION}>
      <div>
        <Heading marginTop={45}>{t('report_vehicle_location_title')}</Heading>
        <Paragraph leftAlign>{t('report_vehicle_location_description')}</Paragraph>
        <QRCodeBackButton />
        {/*
          Because the internal state of downshift doesnt really change so we have to make sure
          only to render the AsyncCombobox when `isFetchingUsersLocation` is false
          otherwise, the initialValue doesn't update as the input's initial value
        */}
        {reportState.isFetchingUsersLocation ? (
          <ComboboxLoadingSkeletal>
            <Image width={20} height={20} src={LocationIcon} alt='current location' />
          </ComboboxLoadingSkeletal>
        ) : (
          <AsyncCombobox
            dataTestid='location'
            initialSelectedItem={initialSelectedItemForAddress}
            onSelect={handleLocationSelect}
            itemToString={itemToString}
            txRequestData={txRequestData}
          />
        )}
        <Paragraph marginTop={40} leftAlign>
          {t('report_vehicle_location_time_description')}
        </Paragraph>
        <DateTimeInput handleDateTimeSelect={handleDateTimeSelect} value={reportedAt} />
      </div>
      <div>
        <Button
          onClick={handleSubmit}
          data-testid='location-button'
          isStretched
          actionType='submit'
          isDisabled={isSubmitDisabled}
        >
          {t('report_vehicle_location_cta')}
        </Button>
      </div>
    </View>
  )
}

export default LocationView
