import styled from 'styled-components'
import theme from 'src/theme'

export const Heading = styled.div<{ marginTop?: number }>`
  text-align: center;
  color: ${theme.colors.common.black};
  font-family: ${theme.fontFamily.primary};
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 'unset')};
`

export const Paragraph = styled.p<{ marginTop?: number; leftAlign?: boolean }>`
  text-align: ${props => (props.leftAlign ? 'left' : 'center')};
  color: ${theme.colors.common.black};
  font-family: ${theme.fontFamily.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.5px;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : 'unset')};
`

export const Footer = styled.footer`
  text-align: center;
  color: ${theme.colors.common.black};
  font-family: ${theme.fontFamily.primary};
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
`
