export const requestAndGetLocation = () =>
  new Promise<GeolocationCoordinates>((resolve, reject) => {
    // might need to put this in effect since ssr
    window.navigator.geolocation.getCurrentPosition(
      position => resolve(position.coords),
      ({ code }) => reject(code),
      { enableHighAccuracy: true },
    )
  })

export type GeolocationError = {
  errorCode: number
  humanErrorText: string
}
export const GeolocationHumanErrors: Array<GeolocationError> = [
  {
    errorCode: 1,
    humanErrorText: 'User denied permission to access location',
  },
  {
    errorCode: 2,
    humanErrorText: 'Position was unavailable',
  },
  {
    errorCode: 3,
    humanErrorText: 'Request timeout',
  },
]

export const getHumanLocationError = (code: number): string => {
  const err = GeolocationHumanErrors.find(error => error.errorCode === code)
  return err ? err.humanErrorText : code.toString()
}
