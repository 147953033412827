import { Dispatch } from 'react'
type Nullable<T> = T | null

export enum ActionType {
  SET_QR_CODE = 'SET_QR_CODE',
  SKIP_QR_CODE = 'SKIP_QR_CODE',
  GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS',
  SET_PHOTOS = 'SET_PHOTOS',
  SKIP_PHOTOS = 'SKIP_PHOTOS',
  SET_REPORT = 'SET_REPORT_REASON',
  GET_USER_LOCATION_REQUEST = 'GET_USER_LOCATION_REQUEST',
  GET_USER_LOCATION_DENIED = 'GET_USER_LOCATION_DENIED',
  SET_IS_SENDING_REPORT = 'SET_IS_SENDING_REPORT',
  SET_REPORT_SEND_SUCCESSFUL = 'SET_REPORT_SEND_SUCCESSFUL',
  SET_REPORT_SEND_FAILED = 'SET_REPORT_SEND_FAILED',
  SET_PAGE = 'SET_PAGE',
  SET_INCIDENT_DATETIME = 'SET_INCIDENT_DATETIME',
}

export enum Page {
  LANDING = 'Landing',
  QR_CODE = 'QRCode',
  LOCATION = 'Location',
  PHOTOS = 'Photos',
  REASON = 'Reason',
  THANK_YOU = 'Thank You',
}

export type Coordinates = {
  latitude: Nullable<number>
  longitude: Nullable<number>
}

export type Action =
  | { type: ActionType.SET_QR_CODE; qrCode: string }
  | { type: ActionType.SKIP_QR_CODE }
  | {
      type: ActionType.GET_USER_LOCATION_SUCCESS
      coordinates: Coordinates
      currentLocationAddress?: string
    }
  | { type: ActionType.GET_USER_LOCATION_REQUEST; isFetchingUsersLocation: boolean }
  | { type: ActionType.GET_USER_LOCATION_DENIED; locationDeniedReason: string }
  | { type: ActionType.SET_PHOTOS; photos: File[] }
  | { type: ActionType.SKIP_PHOTOS }
  | {
      type: ActionType.SET_REPORT;
      reportReason: string;
      reportCategory: string;
      isSendingReport: boolean;
      reportComment: string;
    }
  | { type: ActionType.SET_IS_SENDING_REPORT; isSendingReport: boolean }
  | { type: ActionType.SET_REPORT_SEND_SUCCESSFUL }
  | { type: ActionType.SET_REPORT_SEND_FAILED }
  | { type: ActionType.SET_PAGE; currentPage: Page }
  | { type: ActionType.SET_INCIDENT_DATETIME; reportedAt: string }

export type Context = {
  reportState: State
  dispatch: Dispatch<any>
}

export type State = {
  qrCode: string
  photos: File[]
  coordinates?: Coordinates
  isFetchingUsersLocation: boolean
  currentLocationAddress?: string
  reportReason: string
  reportCategory: string
  reportComment?: string
  isSendingReport: boolean
  pages: Array<Page>
  currentPage: Page
  reportedAt: string
}
