import React from 'react'
import { FieldHookConfig } from 'formik'
import styled from 'styled-components'
import { useField } from 'formik'
import theme from 'src/theme'

export const NativeTextArea = styled.textarea`
  outline: none;
  width: 100%;
  appearance: button;
  select: none;
  padding-end: 20px;
  padding-start: 2px;
  background-position: center right;
  background-repeat: no-repeat;
  border: 3px solid #e3e3e3;
  border-radius: 4px;
  color: ${theme.colors.common.black};
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding: 12px 0 12px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: inherit;
  visibility: inherit;
  transition: border-color 0.1s ease-in-out;
  resize: none;
  :focus-visible {
    border-color: ${theme.colors.primary};
  }
`

export const TextArea = (props: React.HTMLProps<HTMLTextAreaElement> & FieldHookConfig<string>) => {
  const [field] = useField(props.name)
  return <NativeTextArea {...field} {...props} />
}
