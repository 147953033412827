import { RefObject } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'

import theme from 'src/theme'

type InputProps = {
  ref: RefObject<HTMLInputElement>
  onChange?: (value: any) => any
  onBlur?: () => void
  label?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
  dataTestId?: string
  value?: any
  type?: string
  step?: string
  name?: string
  color?: string
  min?: string
  max?: string
  width?: string
  maxWidth?: string
  marginBottom?: string
  maxLength?: number
  row?: boolean
  marginTop?: string
  footnote?: string
  pattern?: RegExp
}

export const Input = styled(Field)`
  border-radius: 28px;
  text-align: center;
  text-transform: uppercase;
  padding: 14px 28px;
  color: ${theme.colors.common.black};
  width: ${props => props.width || '100%'};
  background-color: ${theme.colors.common.white};
  margin-bottom: 20px;
  border: 3px solid ${theme.colors.common.black};
  font-family: ${theme.fontFamily.secondary};
  font-size: 13px;
  &:focus-visible {
    outline: none;
    background-color: ${theme.colors.common.white};
    border-color: ${theme.colors.primary};
  }
  &::placeholder {
    color: ${theme.colors.common.black};
    opacity: 0.5;
  }
`
