import styled from 'styled-components'
import theme from 'src/theme'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import QRCodeIcon from '../../../../../public/qr_code_scanner_black_24dp.svg'
import { useContext } from 'react'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'

const Wrapper = styled.button`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.common.white3};
  align-items: center;
  justify-content: flex-start;
  font-family: ${theme.fontFamily.primary};
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 3px solid transparent;
  &:focus-visible {
    outline: none;
    border: 3px solid ${theme.colors.primary};
  }
`

const QRCodeText = styled.div`
  background-color: inherit;
  padding: 16px 16px 16px 0;
  font-family: inherit;
  font-size: 15px;
`

export const QRCodeBackButton = () => {
  const { t } = useTranslation('common')
  const { reportState, dispatch } = useContext(ReportContext)
  const { qrCode } = reportState

  const handleClick = (e: any) => {
    e.preventDefault()
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.QR_CODE,
    })
  }
  return (
    <Wrapper onClick={handleClick} data-testid='qrcode-preview'>
      <div style={{ margin: '0 16px', display: 'flex' }}>
        <Image width={20} height={20} src={QRCodeIcon} alt='qr' />
      </div>
      <QRCodeText>{qrCode || t('report_vehicle_location_no_qr_code')}</QRCodeText>
    </Wrapper>
  )
}
