import Downshift, { DownshiftState, StateChangeOptions } from 'downshift'
import MarkerIcon from '../../../public/place_black_24dp.svg'
import Image from 'next/image'
import { useState } from 'react'
import { StyledItem, StyledMenu } from './components'
import { AddressInput } from 'src/ui-kit/input/AddressInput'
import { useAsyncOptions } from './useAsyncOptions'
import { useTranslation } from 'next-i18next'

export type Props = {
  dataTestid: string
  onSelect: (item: any) => void
  initialSelectedItem: any
  txRequestData: (data: Array<any>) => Array<any>
  itemToString: (item: any) => string
}

export const AsyncCombobox = ({ dataTestid, onSelect, initialSelectedItem, itemToString, txRequestData }: Props) => {
  const [selection, setSelection] = useState('')
  const { t } = useTranslation('common')
  const networkRequest = useAsyncOptions({
    /**
     * TODO: abtract this as a function which generates urls with params.
     * And then update our hook which debounces requests
     * if there are changes made to the url in
     * Note: this is all assuming that there will be GET requests made
     * and all params and search keys are a part of the url
     */
    searchText: selection,
    params: {
      access_token: process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN,
      fuzzyMatch: true,
    },
  })

  const stateReducer = (state: DownshiftState<any>, changes: StateChangeOptions<any>) => {
    /**
     * Since we have our own state to watch and fetch data on changes, we must do this
     */
    if (changes.inputValue) {
      setSelection(changes.inputValue)
    }
    return changes
  }

  return (
    <Downshift
      itemToString={itemToString}
      initialSelectedItem={initialSelectedItem}
      onSelect={onSelect}
      stateReducer={stateReducer}
    >
      {({
        inputValue,
        getItemProps,
        getInputProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        getToggleButtonProps,
        selectedItem,
      }) => (
        <div style={{ position: 'relative', width: '100%' }}>
          {/* Just 2 css properties. skipping creating a new styled component */}
          <AddressInput
            data-testid={`${dataTestid}-input`}
            value={inputValue}
            getToggleButtonProps={getToggleButtonProps}
            showMenuOnClickOfInput
            {...getInputProps({
              placeholder: selectedItem ? itemToString(selectedItem) : t('add_vehicle_location'),
              /**
               * putting it inside the placeholder and not as a value because downshift doesn't update the
               * input value as a side effect. its managed internally.
               * Best guess: we could have the stateReducer do it but that seems like an overkill
               * since this component, if reused, will not always need a default value updated asyncly.
               * Hence this squeaky little hack :)
               */
            })}
            isLoading={networkRequest.isLoading}
          />
          {txRequestData(networkRequest.data) && (
            <StyledMenu
              data-testid={`${dataTestid}-menu`}
              isOpen={isOpen && !networkRequest.isLoading && txRequestData(networkRequest.data).length}
              {...getMenuProps()}
            >
              {txRequestData(networkRequest.data).map((item, index) => (
                <StyledItem
                  key={index}
                  isHighlighted={highlightedIndex === index}
                  {...getItemProps({
                    item,
                    index,
                  })}
                >
                  <div style={{ margin: '0 16px' }}>
                    <Image src={MarkerIcon} alt={itemToString(item)} height={16} width={16} />
                  </div>
                  <span>{itemToString(item)}</span>
                </StyledItem>
              ))}
            </StyledMenu>
          )}
        </div>
      )}
    </Downshift>
  )
}
