import Image from 'next/image'
import styled from 'styled-components'
import { ReviewButton } from './removeButton'

import theme from 'src/theme'
import { MouseEventHandler } from 'react'

const Wrapper = styled.div`
  position: relative;
  width: 92px;
  height: 80px;
  .styledImage {
    width: 92px;
    height: 80px;
  }
`

const RemoveButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: -20px;
  top: -20px;
`

const ImageWrapper = styled.div`
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: 16px;
  border: 2px solid ${theme.colors.common.white2};
`

export interface ImageThumbnailProps {
  photoName: string
  src: string
  width: number
  height: number
  onRemove: MouseEventHandler<HTMLButtonElement>
}

export const ImageThumbnail = (props: ImageThumbnailProps) => {
  const { src, width, height, onRemove, photoName } = props
  return (
    <Wrapper data-testid={`photo-wrapper-${photoName}`}>
      <RemoveButtonWrapper>
        <ReviewButton
          testId={`photo-remove-button-${photoName}`}
          onClick={onRemove}
        />
      </RemoveButtonWrapper>
      <ImageWrapper>
        <Image
          data-testid={`photo-img-${photoName}`}
          className='styledImage'
          loader={({ src }) => src}
          src={src}
          width={width}
          height={height}
          objectFit='contain'
          alt=''
        />
      </ImageWrapper>
    </Wrapper>
  )
}
