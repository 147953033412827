import styled from 'styled-components'
import theme from 'src/theme'

export const StyledMenu = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  font-family: ${theme.fontFamily.primary};
  font-size: 16px;
  top: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${theme.colors.common.white4};
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  transform: translateY(${props => (props.isOpen ? '58px' : 0)});
  transition: top 0.2s ease-in-out;
`

export const StyledItem = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  padding: 12px 0;
  align-items: stretch;
  :not(:last-child) {
    border-bottom: 1px solid ${theme.colors.common.white4};
  }
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  span {
    width: 80%;
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${props => (props.isHighlighted ? theme.colors.primary : theme.colors.common.black)};
    transition: all 0.1s ease-in-out;
  }
`
