import styled from 'styled-components'

export interface ViewProps {
  isVisible: boolean
}

export const View = styled.div<ViewProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  max-height: calc(100vh - 200px);
  height: calc(100vh - 200px);
  justify-items: center;
  // TODO: in /_app.tsx <ThemeProvider theme={createTheme({  breakPoints })}> https://material-ui.com/customization/
  @media (min-width: 375px) {
    > * {
      width: 100%;
    }
  }
  @media (min-width: 425px) {
    > * {
      width: 40rem;
    }
  }

  ${props =>
    props.isVisible &&
    `
    opacity: 1;
    visibility: visible;
  `}
  ${props =>
    !props.isVisible &&
    `
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    display: none;
  `}
`

export const FormView = styled.div<ViewProps>`
  ${props =>
    props.isVisible &&
    `
    opacity: 1;
    visibility: visible;
  `}
  ${props =>
    !props.isVisible &&
    `
    opacity: 0;
    visibility: hidden;
    max-height: 0px;
    display: none;
  `}
`
