import { useContext } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { Heading, Paragraph } from 'src/ui-kit/typography'
import { View } from 'src/ui-kit/View'
import { ImageWrapper } from 'src/pages/report'
import { ReportContext } from 'src/state/report/report'
import { Page } from 'src/state/report/types'

export const ThankYouView = () => {
  const { reportState } = useContext(ReportContext)
  const { currentPage } = reportState;
  const { t } = useTranslation('common');
  return (
    <View data-testid='thank-you-view' isVisible={currentPage === Page.THANK_YOU}>
      <div>
        <ImageWrapper>
          <Image src='/img-thank_you.svg' width={400} height={200} alt={t(`report_vehicle_thank_you_title`)} />
        </ImageWrapper>
        <Heading>{t(`report_vehicle_thank_you_title`)}</Heading>
        <Paragraph>{t(`report_vehicle_thank_you_description`)}</Paragraph>
      </div>
    </View>
  )
}

export default ThankYouView;