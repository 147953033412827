import { Action, ActionType, State } from 'src/state/report/types'
import { Dispatch } from 'react'
import * as DATALAYER_CONSTANTS from 'src/analytics/report/constants'
import { Analytics } from 'src/analytics/report/tracking'

type Store = {
  getState: () => State
}

export const reportAnalyticsMiddleware = (store: Store) => (next: Dispatch<Action>) => (action: Action) => {
  /*
   * store is an object that gives access to the state via `store.getState()`
   * `next` is either the next middleware in the chain or the `dispatch` function
   * */

  /*
   * This middelware is heavily inspired from https://making.close.com/posts/analytics-and-react-usereducer
   * TL;DR: make actions pass through this middleware, intercept them, send analytics depending on action, forward the action to
   * their own merry way to be picked up by the actual reducer. :)
   * This approach will keep all analytics in one place so we don't have to litter in the UI components
   * */

  /* Additional notes: soon, this shall be merged into just 1 middleware and redux shall be introduced */

  // the state before the reducer is run
  // const prevState = store.getState();
  // const result = next(action)
  // we can now access the new state
  // const state = store.getState();

  const result = next(action)
  const { currentPage, qrCode, photos, reportReason, reportCategory, coordinates } = store.getState()
  const { trackPageView, trackEvent } = Analytics()

  switch (action.type) {
    case ActionType.SET_PAGE: {
      trackPageView(currentPage)
      break
    }
    case ActionType.SET_QR_CODE: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_QR_CODE_PROVIDED, {
        qrCode,
      })
      break
    }
    case ActionType.SKIP_PHOTOS:
    case ActionType.SKIP_QR_CODE: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_SKIPPED_PAGE, {
        pageName: currentPage,
      })
      break
    }

    case ActionType.GET_USER_LOCATION_SUCCESS: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_LOCATION_ACCESS_PROVIDED, {
        coordinates,
      })
      break
    }

    case ActionType.GET_USER_LOCATION_DENIED: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_LOCATION_ACCESS_DENIED, {
        locationDeniedReason: action.locationDeniedReason,
      })
      break
    }

    case ActionType.SET_PHOTOS: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_PHOTOS_PROVIDED, {
        numberOfPhotos: photos.length,
      })
      break
    }

    case ActionType.SET_REPORT: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_REPORT, {
        reportReason,
        reportCategory,
      })
      break
    }

    case ActionType.SET_REPORT_SEND_SUCCESSFUL: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_REPORT_SUBMITTED_SUCCESSFULLY)
      break
    }

    case ActionType.SET_REPORT_SEND_FAILED: {
      trackEvent(DATALAYER_CONSTANTS.EVENT_REPORT_SUBMITTED_FAILED)
      break
    }

    default: {
    }
  }
  return result
}
