import styled, { keyframes } from 'styled-components'
import theme from '../../../theme'

const blink = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
`

export const BlinkingDot = styled.span`
  height: 4px;
  width: 4px;
  float: left;
  margin: 0 1px;
  background-color: ${theme.colors.common.black};
  display: block;
  border-radius: 2px;
  opacity: 0.4;
  animation: ${blink} 0.8s infinite;

  &:nth-of-type(1) {
    animation-delay: 0.3333s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.6666s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.9999s;
  }
`

export const StyledTyping = styled.div<{ isVisible: boolean }>`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  display: flex;
  transform: translateX(${props => (props.isVisible ? 0 : '-200px')});
  transition: translate 0.1s ease-in-out, opacity 0.2s ease-in-out;
`

export type Props = {
  isVisible: boolean
}
export const Typing = ({ isVisible }: Props) => (
  <StyledTyping isVisible={isVisible}>
    <BlinkingDot />
    <BlinkingDot />
    <BlinkingDot />
  </StyledTyping>
)
