export enum REASON_CATEGORIES {
  select = 'report_vehicle_reason_select', // will be used as a phrase key
  badParking = 'bad_parking',
  antiSocialBehavior = 'anti_social_behaviour',
  other = 'other'
}

export enum BAD_PARKING {
  select = 'report_vehicle_reason_select',
  BlockingPavement= 'blocking_pavement',
  Clutter = 'clutter',
  BlockingAccess = 'blocking_access',
  BlockingTraffic = 'blocking_traffic',
  ToppledOver = 'toppled_over',
  NotInTheZone = 'not_in_the_zone',
  Other = 'other',
}

export enum ANTI_SOCIAL_BEHAVIOR {
  select = 'report_vehicle_reason_select',
  TwinRiding= 'twin_riding',
  DangerousRidingOnTheRoad= 'dangerous_riding_on_the_road',
  UnderageRiding = 'underage_riding',
  PavementRiding = 'pavement_riding',
  NotFollowingTrafficRules = 'not_following_traffic_rules',
  DrunkRiding = 'drunk_riding',
}

export enum MISCELLANEOUS {
  select = 'report_vehicle_reason_select',
  NoiseComplaints = 'noise_complaints',
  Other = 'other'
}

export const REPORT_REASONS = {
  [REASON_CATEGORIES.select]: [],
  [REASON_CATEGORIES.badParking]: [
    BAD_PARKING.select,
    BAD_PARKING.BlockingPavement,
    BAD_PARKING.BlockingAccess,
    BAD_PARKING.BlockingTraffic,
    BAD_PARKING.Clutter,
    BAD_PARKING.ToppledOver,
    BAD_PARKING.NotInTheZone,
    BAD_PARKING.Other,
  ],
  [REASON_CATEGORIES.antiSocialBehavior]: [
    ANTI_SOCIAL_BEHAVIOR.select,
    ANTI_SOCIAL_BEHAVIOR.TwinRiding,
    ANTI_SOCIAL_BEHAVIOR.DangerousRidingOnTheRoad,
    ANTI_SOCIAL_BEHAVIOR.UnderageRiding,
    ANTI_SOCIAL_BEHAVIOR.PavementRiding,
    ANTI_SOCIAL_BEHAVIOR.NotFollowingTrafficRules,
    ANTI_SOCIAL_BEHAVIOR.DrunkRiding,
  ],
  [REASON_CATEGORIES.other]: [
    MISCELLANEOUS.select,
    MISCELLANEOUS.NoiseComplaints,
    MISCELLANEOUS.Other,
  ],
}
