import React, { MouseEventHandler } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

import theme from 'src/theme'

export interface ButtonProps {
  isLoading: boolean
  isStretched?: boolean
  isDisabled?: boolean
  actionType?: 'submit' | 'button' | 'reset'
  variant?: 'primary' | 'secondary' | 'text'
  children?: any
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Content = styled.div<{ isLoading: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.isLoading ? 'hidden' : 'visible')};
  opacity: ${props => (props.isLoading ? 0 : 1)};
`

const LoadingState = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BaseButton = styled.button<ButtonProps>`
  border-radius: 30px;
  letter-spacing: 0.65px;
  outline: none;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  padding: 18px 32px;
  font-weight: bold;
  width: ${({ isStretched }) => (isStretched ? '100%' : undefined)};
  min-width: 124px;
  user-select: none;
  font-family: ${theme.fontFamily.secondary};
  font-size: 13px;
  line-height: 13px;
  border: 3px solid transparent;
  margin-bottom: 20px;

  ${props =>
    props.variant === 'primary' &&
    `
      background-color: ${theme.colors.primary};
      color: ${theme.colors.common.white};
    `}
  ${props =>
    props.variant === 'secondary' &&
    `
      background-color: ${theme.colors.common.black};
      color: ${theme.colors.common.white};
    `}
  ${props =>
    props.variant === 'text' &&
    `
      background-color: ${theme.colors.common.white};
      color: ${theme.colors.common.black};
      font-size: 10px;
      letter-spacing: 0.5px;
    `}

  &:active {
    opacity: ${({ isLoading }) => (isLoading ? '1' : '0.8')};
  }

  &:focus-visible {
    border: 3px solid #243860;
  }

  &:hover {
    cursor: ${({ isDisabled, isLoading }) => {
      if (isDisabled) {
        return 'not-allowed'
      } else if (isLoading) {
        return 'default'
      }
      return 'pointer'
    }};
  }
`

export const Button = (props: ButtonProps) => {
  const { children, actionType, isLoading, isDisabled, variant, isStretched, onClick, ...rest } = props

  return (
    <BaseButton
      type={actionType}
      disabled={isDisabled}
      isDisabled={isDisabled}
      variant={variant}
      isStretched={isStretched}
      isLoading={isLoading}
      onClick={onClick}
      {...rest}
    >
      {isLoading && (
        <LoadingState>
          <CircularProgress color='inherit' size={18} />
        </LoadingState>
      )}
      <Content isLoading={isLoading}>{children}</Content>
    </BaseButton>
  )
}

Button.defaultProps = {
  isLoading: false,
  isStretched: false,
  isDisabled: false,
  variant: 'primary',
  actionType: 'button',
}
