import { MouseEvent, useEffect, useContext } from 'react'
import Head from 'next/head'
import styled from 'styled-components'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import BackButton from 'src/ui-kit/backButton'
import { GetStaticProps } from 'next'
import QrCodeView from 'src/components/report/QRCodeView'
import LocationView from 'src/components/report/LocationView/LocationView'
import LandingView from 'src/components/report/LandingView/LandingView'
import ReasonView from 'src/components/report/ReasonView'
import ThankYouView from 'src/components/report/ThankYouView'
import PhotoView from 'src/components/report/PhotoView'
import { ReportContext, ReportProvider } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'
import { makeFormData } from 'src/components/report/util'
import * as yup from 'yup'

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ReportPage = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  max-width: 100vw;
`

const BackButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 2rem;
`

export const Report = () => {
  const { reportState, dispatch } = useContext(ReportContext)
  const { currentPage, pages } = reportState
  const { reportReason, reportCategory, isSendingReport } = reportState
  const { t } = useTranslation('common')

  const reportValidationSchema = yup.object().shape({
    reportReason: yup.string().min(2).required(),
    reportCategory: yup.string().min(2).required(),
    isSendingReport: yup.boolean().isTrue().required(),
  })

  const goBack = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: pages[pages.findIndex(page => page === currentPage) - 1],
    })
  }

  useEffect(() => {
    reportValidationSchema
      .isValid({
        reportReason,
        reportCategory,
        isSendingReport,
      })
      .then(isReportValid => {
        if (isReportValid) {
          const formData = makeFormData(reportState)

          fetch('/api/report', {
            method: 'POST',
            body: formData,
          })
            .then(res => {
              dispatch({
                type: res.ok ? ActionType.SET_REPORT_SEND_SUCCESSFUL : ActionType.SET_REPORT_SEND_FAILED,
              })
              dispatch({
                type: ActionType.SET_PAGE,
                currentPage: Page.THANK_YOU,
              })
            })
            .catch(err => {
              dispatch({
                type: ActionType.SET_REPORT_SEND_FAILED,
              })
              throw Error(err)
            })
        }
      })
  }, [reportReason, reportCategory, isSendingReport])

  useEffect(() => {
    /*
     * Landing will still be displayed without this side effect
     * but we must dispatch here because analytics are hooked up in middlewares
     * */

    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.LANDING,
    })
  }, [])

  return (
    <ReportPage data-testid='page-view'>
      <Head>
        <title>Voi Scooters - {t('report_vehicle_landing_title')}</title>
        <meta name='description' content={t('report_vehicle_landing_description')} />
      </Head>
      <BackButtonWrapper>
        <BackButton
          data-testid='back-button'
          onClick={goBack}
          isHidden={currentPage === Page.LANDING || currentPage === Page.THANK_YOU || isSendingReport}
        />
      </BackButtonWrapper>
      <LandingView />
      <LocationView />
      <QrCodeView />
      <PhotoView />
      <ReasonView />
      <ThankYouView />
    </ReportPage>
  )
}

export default function ReportWithProvider() {
  return (
    <ReportProvider>
      <Report />
    </ReportProvider>
  )
}

type GetStaticPropsWithLocale = GetStaticProps & {
  locale: 'da' | 'de' | 'en' | 'es' | 'fi' | 'fr' | 'it' | 'no' | 'sv'
}

export const getStaticProps = async ({ locale }: GetStaticPropsWithLocale) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
})
