import styled from 'styled-components'
import { useField } from 'formik'
import theme from 'src/theme'

export const NativeSelect = styled.select`
  outline: none;
  width: 100%;
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-position: center right;
  background-repeat: no-repeat;
  border: 3px solid #e3e3e3;
  border-radius: 4px;
  color: ${theme.colors.common.black};
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding: 12px 0 12px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: inherit;
  visibility: inherit;
  transition: border-color 0.1s ease-in-out;
  :focus-visible {
    border-color: ${theme.colors.primary};
  }
`
/* @ts-ignore */
export const Select = props => {
  const [field] = useField(props.name)
  return <NativeSelect {...field} {...props} />
}
