import { State } from 'src/state/report/types'

export const makeFormData = (state: State): FormData => {
  const { qrCode, coordinates, photos, reportCategory, reportReason, reportComment, reportedAt } = state;

  const formData = new FormData()

  if (qrCode.length) {
    formData.append('qrCode', qrCode)
  }
  if (coordinates?.latitude) {
    formData.append('latitude', coordinates?.latitude.toString())
  }
  if (coordinates?.longitude) {
    formData.append('longitude', coordinates?.longitude.toString())
  }
  if (photos.length) {
    for (let photo of photos) {
      formData.append('photos[]', photo)
    }
  }
  if (reportComment?.length) {
    formData.append('reportComment', reportComment)
  }

  formData.append('reportCategory', reportCategory)
  formData.append('reportReason', reportReason)
  formData.append('reportedAt', reportedAt)

  return formData;
}