import styled from 'styled-components'
import theme from 'src/theme'
import { MouseEventHandler } from 'react'

interface Props {
  isDisabled?: boolean
  testId?: string
  className?: string
  alt?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ButtonContainer = styled.div`
  width: 46px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const BaseButton = styled.button<{ isDisabled?: boolean }>`
  width: 24px;
  height: 24px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.common.white};
  border-radius: 50%;
  border: 2px solid ${theme.colors.common.white};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};

  &:active:not(:disabled) {
    box-shadow: none;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:hover {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`

export const ReviewButton = ({
  isDisabled,
  testId,
  className,
  onClick,
}: Props) => (
  <ButtonContainer>
    <BaseButton
      disabled={isDisabled}
      isDisabled={isDisabled}
      data-testid={testId}
      className={className}
      type='button'
      onClick={onClick}
    >
      x
    </BaseButton>
  </ButtonContainer>
)
