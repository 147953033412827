export const EVENT_SKIPPED_PAGE = 'Page skip'

export const EVENT_QR_CODE_PROVIDED = 'Provided QR code'

export const EVENT_REQUESTING_LOCATION = 'Requesting location'
export const EVENT_LOCATION_ACCESS_DENIED = 'Denied location'
export const EVENT_LOCATION_ACCESS_PROVIDED = 'Provided location'

export const EVENT_PHOTOS_PROVIDED = 'Provided photos'
export const EVENT_REPORT = 'Provided report category and reason'

export const EVENT_REPORT_SUBMITTED_SUCCESSFULLY = 'Report submission succeeded'
export const EVENT_REPORT_SUBMITTED_FAILED = 'Report submission failed'
