import { Action, ActionType, Page, State } from './types'
import { PAGES } from './constants'

export const INITIAL_STATE = {
  qrCode: '',
  photos: [],
  coordinates: {
    latitude: null,
    longitude: null,
  },
  isFetchingUsersLocation: false,
  currentLocationAddress: '',
  reportedAt: '',
  reportReason: '',
  reportCategory: '',
  reportComment: '',
  isSendingReport: false,
  pages: PAGES,
  currentPage: Page.LANDING,
}

export const reportReducer = (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_QR_CODE: {
      return { ...state, qrCode: action.qrCode }
    }
    case ActionType.SKIP_QR_CODE: {
      return { ...state, qrCode: '' }
    }
    case ActionType.SET_PHOTOS: {
      return {
        ...state,
        photos: action.photos,
      }
    }
    case ActionType.SET_REPORT: {
      return {
        ...state,
        reportReason: action.reportReason,
        reportCategory: action.reportCategory,
        reportComment: action.reportComment,
        isSendingReport: true,
      }
    }
    case ActionType.GET_USER_LOCATION_REQUEST: {
      return {
        ...state,
        isFetchingUsersLocation: action.isFetchingUsersLocation,
      }
    }
    case ActionType.GET_USER_LOCATION_SUCCESS: {
      return {
        ...state,
        coordinates: action.coordinates,
        currentLocationAddress: action.currentLocationAddress,
        isFetchingUsersLocation: false,
      }
    }
    case ActionType.GET_USER_LOCATION_DENIED: {
      return {
        ...state,
        isFetchingUsersLocation: false,
      }
    }

    case ActionType.SET_IS_SENDING_REPORT: {
      return {
        ...state,
        isSendingReport: action.isSendingReport,
      }
    }
    case ActionType.SET_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
      }
    }
    case ActionType.SET_INCIDENT_DATETIME: {
      return {
        ...state,
        reportedAt: action.reportedAt,
      }
    }
    default:
      return state
  }
}
