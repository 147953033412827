import DatePicker from 'react-datepicker'

import styled from 'styled-components'
import theme from 'src/theme'

export type Props = {
  handleDateTimeSelect: (ev: any) => void
  value: any
}

export const InputWrapper = styled.div`
  display: flex;
  border: 3px solid transparent;
  overflow: hidden;
  background-color: ${theme.colors.common.white3};
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  font-family: ${theme.fontFamily.primary};
  border-radius: 4px;
  transition: border 0.2s ease-in-out;
  :focus-within {
    border: 3px solid ${theme.colors.primary};
    background-color: ${theme.colors.common.white};
  }
`

export const StrippedInput = styled.div`
  background-color: inherit;
  border: none;
  outline: none;
  width: 100%;
  padding: 16px;
  font-family: inherit;
  font-size: 15px;
`

export const DateTimeInput = ({ handleDateTimeSelect, value }: Props) => {
  const currentTime = new Date()

  return (
    <InputWrapper>
      <DatePicker
        dropdownMode='scroll'
        className='date-picker'
        autoComplete='off'
        onChange={(date: any) => handleDateTimeSelect(date)}
        placeholderText='yyyy-mm-dd'
        name='report_date'
        id='report_date'
        dateFormat='yyyy-MM-dd h:mm aa'
        maxDate={currentTime}
        popperPlacement='bottom'
        selected={value as any}
        showTimeInput
      />
    </InputWrapper>
  )
}
