import { useContext } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'next-i18next'
import { Heading, Paragraph } from 'src/ui-kit/typography'
import { Input } from 'src/ui-kit/input'
import { Button } from 'src/ui-kit/button'
import { View } from 'src/ui-kit/View'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'

const QRCodeSchema = Yup.object().shape({
  qrCode: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9]{4}$/)
    .required(),
})

export const QrCodeView = () => {
  const { t } = useTranslation('common')

  const { reportState, dispatch } = useContext(ReportContext)
  const { currentPage } = reportState

  const setQrCode = (qrCode: string) => {
    dispatch({
      type: ActionType.SET_QR_CODE,
      qrCode,
    })
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.LOCATION,
    })
  }

  const skipQrCode = () => {
    dispatch({
      type: ActionType.SKIP_QR_CODE,
    })
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.LOCATION,
    })
  }

  return (
    <View data-testid='submit-qr-code-view' isVisible={currentPage === Page.QR_CODE}>
      <div>
        <Heading>{t('report_vehicle_qrcode_title')}</Heading>
        <Paragraph>{t('report_vehicle_qrcode_description')}</Paragraph>
      </div>
      <Formik
        validateOnMount
        validationSchema={QRCodeSchema}
        initialValues={{
          qrCode: '',
        }}
        onSubmit={({ qrCode }) => setQrCode(qrCode.trim())}
      >
        {({ errors }) => (
          <Form>
            <Input
              name='qrCode'
              data-testid='qr-code-input'
              placeholder={t('report_vehicle_qrcode_input_placeholder')}
            />
            <Button data-testid='qr-code-button' isStretched actionType='submit' isDisabled={Boolean(errors.qrCode)}>
              {t('report_vehicle_qrcode_cta')}
            </Button>
            <Button
              data-testid='skip-qr-code-button'
              actionType='button'
              isStretched
              variant='text'
              onClick={skipQrCode}
            >
              {t('report_vehicle_qrcode_skip_cta')}
            </Button>
          </Form>
        )}
      </Formik>
    </View>
  )
}

export default QrCodeView
