import axios from 'axios'

export const getAddressFromCoordinates = (coordinates: GeolocationCoordinates) => {
  const url =
    'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
    coordinates.longitude +
    ',' +
    coordinates.latitude +
    '.json?access_token=' +
    process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN +
    '&types=address'
  return axios.get(url).then(res => {
    const addressList = res.data.features.filter((e: any) => e.place_type[0] === 'address')
    if (addressList.length > 0) {
      return addressList[0].place_name
    }
    return ''
  })
}

// TODO: Add &proximity=long,lat if available
export const getCoordinatesFromAddress = (addressText: string) => {
  let url =
    'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
    addressText +
    '.json?access_token=' +
    process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN +
    '&autocomplete=true'

  return axios.get(url).then(res => res.data.features)
}
