import Image from 'next/image'
import { MouseEventHandler } from 'react'
import styled from 'styled-components'

export type BackButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
  isHidden: boolean
}

const StyledButton = styled.button<{ isHidden: boolean }>`
  background-color: transparent;
  outline: none;
  border-radius: 50%;
  border: none;
  width: 32px;
  height: 32px;
  box-shadow: #00000029 0 3px 6px;
  cursor: pointer;
  display: ${props => (props.isHidden ? 'none' : 'block')};
  border: 3px solid transparent;
  &:focus-visible {
    border: 3px solid #243860;
  }
`

export const BackButton = (props: BackButtonProps) => {
  const { onClick, isHidden, ...rest } = props
  return (
    <StyledButton onClick={onClick} isHidden={isHidden} {...rest}>
      <Image src='/ic_chevron-left-solid.svg' alt='back' width={32} height={32} />
    </StyledButton>
  )
}
export default BackButton
