const getCookieSettings = () => {
  if (typeof document !== 'undefined' && document.cookie) {
    let cookies = document.cookie.split('; ')
    let consentCookieString = cookies.find(row => row.startsWith('cconsent='))
    if (consentCookieString) {
      return JSON.parse(consentCookieString.split('=')[1]).categories
    }
  }
  return {
    performance: {},
    targeting: {},
  }
}

export const Analytics = () => {
  try {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
  } catch (e) {}

  return {
    trackPageView: (pageName: string) => {
      const { performance } = getCookieSettings() // because the cookie might have changed
      if (performance.wanted) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'pageview',
          pageName,
        })
      }
    },
    trackEvent: (event: string, data = {}) => {
      const { performance } = getCookieSettings()
      if (performance.wanted) {
        // @ts-ignore
        window.dataLayer.push({
          event,
          data,
        })
      }
    },
  }
}
