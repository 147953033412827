import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { Footer, Heading, Paragraph } from 'src/ui-kit/typography'
import { Button } from 'src/ui-kit/button'
import { View } from 'src/ui-kit/View'
import { ImageWrapper } from 'src/pages/report'
import { useContext, useEffect } from 'react'
import { ReportContext } from 'src/state/report/report'
import { ActionType, Page } from 'src/state/report/types'
import { requestAndGetLocation, getHumanLocationError } from './util'
import { getAddressFromCoordinates } from 'src/components/report/LocationView/util'

export const LandingView = () => {
  const { t } = useTranslation('common')
  const { reportState, dispatch } = useContext(ReportContext)
  const { currentPage } = reportState

  const handleNext = () => {
    dispatch({
      type: ActionType.SET_PAGE,
      currentPage: Page.QR_CODE,
    })
  }

  async function setUsersCurrentLocation() {
    try {
      dispatch({
        type: ActionType.GET_USER_LOCATION_REQUEST,
        isFetchingUsersLocation: true,
      })

      const coordinates = await requestAndGetLocation()
      const currentLocationAddress = await getAddressFromCoordinates(coordinates)
      dispatch({
        type: ActionType.GET_USER_LOCATION_SUCCESS,
        currentLocationAddress,
        coordinates: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        },
      })
    } catch (code) {
      dispatch({
        type: ActionType.GET_USER_LOCATION_DENIED,
        locationDeniedReason: getHumanLocationError(code),
      })
      Sentry.captureMessage(getHumanLocationError(code), {})
    }
  }

  useEffect(() => {
    setUsersCurrentLocation()
  }, [])

  return (
    <View data-testid='report-landing-view' isVisible={currentPage === Page.LANDING}>
      <div>
        <ImageWrapper>
          <Image src='/img-report_a_voi.svg' width={400} height={250} alt={t('report_vehicle_landing_title')} />
        </ImageWrapper>
        <div style={{ marginTop: '16px' }}>
          <Heading>{t('report_vehicle_landing_title')}</Heading>
        </div>
        <Paragraph>{t('report_vehicle_landing_description')}</Paragraph>
      </div>
      <div>
        <div
          style={{
            marginBottom: '32px',
          }}
        >
          <Button data-testid='report-button' actionType='button' isStretched onClick={() => handleNext()}>
            {t('report_vehicle_landing_cta')}
          </Button>
        </div>
        <Footer>{t('report_vehicle_landing_footer')}</Footer>
      </div>
    </View>
  )
}

export default LandingView
